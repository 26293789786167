<template>

  <div>

    <!-- Hero section Institucional -->
    <section
      v-if="topoInternas_bg !=''"
      class="hero-section hero-section-inst set-img d-flex align-items-end"
      :style="{ backgroundImage: 'url(' + topoInternas_bg + ')' }"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center m_bottom-50">
            <h2>{{ topoInternasTitulo }}</h2>
          </div>
        </div>
      </div>
    </section> 


    <section
      v-else
      class="hero-section hero-section-inst set-img d-flex align-items-end"
      style="background-color: #ccc;"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center m_bottom-50">
            <h2>{{ topoInternasTitulo }}</h2>
          </div>
        </div>
      </div>
    </section>    
    <!------------------------- Fim Header Institucional -->



    <!-- Blog single -->
    <section class="spad-100 single-blog border-gradient-bottom">
      <div class="container">
        <div class="row">				
          <div class="col singel-blog-content">
            <div class="section-title mb-4">
              <h3 style="font-weight: bold;">{{ noticiaTitulo }}</h3>
            </div>
            <img class="mb-5" :src="noticiaImg">
            <p v-html="noticiaDescricao"></p>
          </div>
        </div>
      </div>
    </section>   

  </div>
</template>

<script>

// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {

  },
  data() {
    return {

      topoInternas: [],
      topoInternasTitulo: '',
      topoInternas_bg: '',

      noticia: [],
      noticiaTitulo: '',
      noticiaDescricao: '',
      noticiaImg: '',

    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/blog%20topo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoInternasTitulo = this.topoInternas[0].nome
      this.topoInternas_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });


    // this.$http
    // .get(this.$apiUrl + "/institucional/telas/noticias")
    // .then((res) => res.json())
    // .then((retornoAPI) => {
    //   this.blog = retornoAPI

    // });


    this.carregaNoticia(this.$route.params.idNoticia)

  },
  methods: {
    carregaNoticia(idNoticia) {    
      this.$http
      .get(this.$apiUrl + "/institucional/" + idNoticia)
      .then((res) => res.json())
      .then((retornoAPI) => {
        this.noticia = retornoAPI

        this.noticiaTitulo = this.noticia.nome
        this.noticiaDescricao = this.noticia.descricao1
        this.noticiaImg = this.$apiUrlIndex + this.noticia.institucionalImg[0].urlImagem
        
          
      });
    }

  }
  
}

</script>

