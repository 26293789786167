<template>

  <div>

    <!-- Hero section Institucional -->
    <section
      v-if="topoInternas_bg !=''"
      class="hero-section hero-section-inst set-img d-flex align-items-end"
      :style="{ backgroundImage: 'url(' + topoInternas_bg + ')' }"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center m_bottom-50">
            <h2>{{ topoInternasTitulo }}</h2>
          </div>
        </div>
      </div>
    </section> 


    <section
      v-else
      class="hero-section hero-section-inst set-img d-flex align-items-end"
      style="background-color: #ccc;"
    >
      <div class="container">
        <div class="row">
          <div class="col text-center m_bottom-50">
            <h2>{{ topoInternasTitulo }}</h2>
          </div>
        </div>
      </div>
    </section>    
    <!------------------------- Fim Header Institucional -->


    <!-- Blog -->
    <section class="spad-100 border-gradient-bottom">
      <div class="container">
        <div class="row">

          <div 
            class="blog-item col-lg-4 col-md-6"
            v-for="noticia in blog"
            :key="noticia.id"
          >
            <img 
              v-if="noticia.institucionalImg !=''"
              class="w-100" 
              :src="$apiUrlIndex + noticia.institucionalImg[0].urlImagem"
            >
            <h5><router-link :to="'/blogDetalhe/' + noticia.nome.replaceAll(' ','-').replaceAll('/','') + '/' + noticia.institucionalId">{{ noticia.nome }}</router-link></h5>
            <p v-html="noticia.resumo1"></p>
            <div class="mt-4">
              <router-link :to="'/blogDetalhe/' + noticia.nome.replaceAll(' ','-').replaceAll('/','') + '/' + noticia.institucionalId"
              >
                <span class="blog-item-read">
                  Leia Mais 
                  <i class="fa fa-arrow-right"></i>
                </span>
              </router-link>
            </div>
          </div>         

        </div>
      </div>
    </section>    

  </div>
</template>

<script>

// import Depoimentos from '../shared/Depoimentos.vue'

export default {
  components: {

  },
  data() {
    return {

      topoInternas: [],
      topoInternasTitulo: '',
      topoInternas_bg: '',

      blog: []

    }
  },
  created() {

    this.$http
    .get(this.$apiUrl + "/institucional/telas/blog%20topo")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.topoInternas = retornoAPI

      this.topoInternasTitulo = this.topoInternas[0].nome
      this.topoInternas_bg = this.$apiUrlIndex + this.topoInternas[0].institucionalImg[0].urlImagem

    });


    this.$http
    .get(this.$apiUrl + "/institucional/telas/noticias")
    .then((res) => res.json())
    .then((retornoAPI) => {
      this.blog = retornoAPI.filter((x) => x.visivel)

    });

  }
  
}

</script>

